<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-dialog persistent v-model="dialog" max-width="500px" transition="dialog-transition">
    <template>
      <v-card>
        <v-toolbar color="info" dark
          ><span> Manage {{ nomName }} </span> <v-spacer></v-spacer>
          <div class="mt-4 mx-4 pt-1"></div
        ></v-toolbar>
        <v-card-text class="d-flex justify-center mt-2">
          <template v-if="nomName == 'Tags'">
            <v-autocomplete
              class="mx-1 my-3"
              :label="`Select ${nomName}`"
              :items="tags"
              v-model="tagsIds"
              item-text="name"
              item-value="id"
              multiple
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
              outlined
              dense
            >
              <template v-slot:prepend-inner>
                <template v-if="!checkAllTags">
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          @click="checkAllTags = true"
                          v-text="'mdi-check-all'"
                        ></v-icon>
                      </template>
                      <span>Select All Tags</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </template>
              </template>
            </v-autocomplete>
          </template>
          <template v-if="nomName == 'Npn'">
            <v-autocomplete
              class="mx-1 my-3"
              :label="`Select ${nomName}`"
              :items="npns"
              v-model="body.npnNomId"
              item-text="name"
              item-value="id"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
              outlined
              dense
            >
            </v-autocomplete>
          </template>
          <template v-if="nomName == 'Pay To'">
            <v-autocomplete
              class="mx-1 my-3"
              :label="`Select ${nomName}`"
              :items="paysTo"
              v-model="body.payToNomId"
              item-text="name"
              item-value="id"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
              outlined
              dense
            >
            </v-autocomplete>
          </template>
          <template v-if="nomName == 'Payment Status'">
            <v-autocomplete
              class="mx-1 my-3"
              :label="`Select ${nomName}`"
              :items="paymentStatus"
              v-model="body.payToNomId"
              item-text="name"
              item-value="id"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
              outlined
              dense
            >
            </v-autocomplete>
          </template>
          <template v-if="nomName == 'OP Status'">
            <v-autocomplete
              class="mx-1 my-3"
              :label="`Select ${nomName}`"
              :items="renewalStatus"
              v-model="body.renewalStatusNomId"
              item-text="name"
              item-value="id"
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
              outlined
              dense
            >
            </v-autocomplete>
          </template>
        </v-card-text>
        <v-card-actions class="justify-end">
          <template v-if="isAdmins && nomName == 'Tags'">
            <v-btn
              :disabled="loading || loadingD"
              :loading="loading"
              color="success"
              text
              @click="confirmAsAdmin()"
              >Confirm As Admin</v-btn
            >
          </template>
          <v-btn :disabled="loading || loadingD" text @click="cancel()">Close</v-btn>
          <v-btn
            :loading="loadingD"
            :disabled="loading || loadingD"
            color="error"
            text
            @click="confirmDelete()"
            >Delete</v-btn
          >
          <v-btn
            :disabled="loading || loadingD"
            :loading="loading"
            color="info"
            text
            @click="confirm()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo, notifySuccess } from "@/components/Notification";
import cleanObject from "@/utils/cleanObject";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  props: {
    clientsUuids: [],
    openDialog: false,
    nomName: String,
    npns: [],
    tags: [],
    paysTo: [],
    paymentStatus: [],
    renewalStatus: [],
  },
  name: "crm-change-noms",
  data() {
    return {
      dialog: false,
      loading: false,
      loadingD: false,
      isAdmins: false,
      checkAllTags: false,
      tagsIds: [],
      body: {
        patientUuids: [],
        tagsIds: [],
        mustDelete: false,
        asAdmin: false,
        npnNomId: null,
        payToNomId: null,
        renewalStatusNomId: null,
      },
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.dialog = val;
      }
    },
    /*  nomName(val){

    }    */
    tagsIds: {
      handler(val) {
        this.body.tagsIds = val;
        if (val && val.length != this.tags.length) {
          this.checkAllTags = false;
        } else if (val && val.length == this.tags.length && this.checkAllTags == false) {
          this.checkAllTags = true;
        }
      },
    },
    checkAllTags(val) {
      if (val) {
        if (this.tagsIds == null || this.tags.length != this.tagsIds.length) {
          this.tagsIds = this.tags.map((t) => {
            return t.id;
          });
        }
      }
    },
  },

  computed: {
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor", "getCoordinatorN"]),

    validButtons() {
      switch (this.nomName) {
        case "Tags":
          break;
        case "Npn":
          break;
        case "Pay To":
          break;
        case "Renewal Status":
          break;
      }
    },
  },
  mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
    }
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", ["actAsignToLead", "actMultiAsignToLead"]),

    cancel(updated) {
      this.dialog = false;
      this.tagsIds = [];
      this.body = {
        patientUuids: [],
        tagsIds: [],
        mustDelete: false,
        asAdmin: false,
        npnNomId: null,
        payToNomId: null,
        renewalStatusNomId: null,
      };
      this.checkAllTags = false;
      this.loading = false;
      if (updated) {
        this.$emit("updated", true);
      } else {
        this.$emit("updated", false);
      }
    },

    async confirm() {
      this.body.patientUuids = this.clientsUuids.map((clU) => {
        return clU.uuid;
      });
      this.body.mustDelete = false;
      let bodyreq = Object.assign({}, this.body);
      bodyreq = cleanObject(bodyreq);
      if (bodyreq.tagsIds.length == 0) {
        delete bodyreq.tagsIds;
      }
      this.loading = true;
      getAPI
        .put("/patient/updateMasiveNoms", bodyreq)
        .then(() => {
          notifyInfo(this.$t(`${this.nomName} has been changed`));
          this.cancel(true);
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async confirmAsAdmin() {
      this.body.patientUuids = this.clientsUuids.map((clU) => {
        return clU.uuid;
      });
      this.body.mustDelete = false;
      let bodyreq = Object.assign({}, this.body);
      bodyreq.asAdmin = true;
      bodyreq = cleanObject(bodyreq);
      if (bodyreq.tagsIds.length == 0) {
        delete bodyreq.tagsIds;
      }
      this.loading = true;
      getAPI
        .put("/patient/updateMasiveNoms", bodyreq)
        .then(() => {
          notifyInfo(this.$t(`${this.nomName} has been changed`));
          this.cancel(true);
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async confirmDelete() {
      this.body.patientUuids = this.clientsUuids.map((clU) => {
        return clU.uuid;
      });

      this.body.mustDelete = true;
      let bodyreq = Object.assign({}, this.body);
      if (this.isAdmins) {
        bodyreq.asAdmin = true;
      }
      bodyreq = cleanObject(bodyreq);
      if (bodyreq.tagsIds.length == 0) {
        delete bodyreq.tagsIds;
      }
      this.loadingD = true;
      getAPI
        .put("/patient/updateMasiveNoms", bodyreq)
        .then(() => {
          notifyInfo(this.$t(`${this.nomName} has been changed`));
          this.cancel(true);
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingD = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
<style lang="scss" scope></style>
