<script>
import { isSuper } from "@/store/getters";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-layout class="patient-list">
    <v-card class="rounded-xl overflow-hidden" :disabled="disableWhileLoading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ getpatientN + "s" }}</div>
        <div class="mx-4 mt-4 pt-1">
          <v-switch
            class="font-weight-bold white--text"
            label="No Relationship"
            :loading="disableWhileLoading"
            :disabled="disableWhileLoading"
            inset
            @change="getPatients"
            v-model="noCoordinator"
          ></v-switch>
        </div>
        <div class="mt-4 mx-4 pt-1">
          <v-switch
            inset
            color="white"
            @change="getPatients()"
            v-model="cotizedOnly"
            :loading="loading"
            :label="`Quoted`"
          ></v-switch>
        </div>
        <v-spacer />
        <div class="mx-4 mt-4 pt-2">
          <v-select
            :items="lang"
            v-model="preferLanguage"
            item-text="label"
            item-value="value"
            :loading="loading"
            outlined
            @change="getPatients"
            dense
            label="by Language"
          ></v-select>
        </div>
        <div></div>

        <template v-if="selected.length != 0">
          <v-dialog
            persistent
            v-model="transferDialog"
            transition="dialog-top-transition"
            max-width="600"
          >
            <template>
              <v-card>
                <v-toolbar color="primary" dark>Select Employee</v-toolbar>
                <v-card-text>
                  <div class="text-h2 pt-4 px-4 d-flex">
                    <v-select
                      v-model="employeeUuidt"
                      label="Employees"
                      :items="workers"
                      prepend-inner-icon="mdi-doctor"
                      item-text="fullname"
                      item-value="uuid"
                      :loading="loadingOcup"
                      hide-details
                      clearable
                      outlined
                      dense
                    />
                    <v-select
                      class="ml-1"
                      :disabled="employeeUuidt == '' || employeeUuidt == null"
                      v-model="relation.ocupationId"
                      label="Occupations"
                      :items="ocupations"
                      item-text="name"
                      item-value="id"
                      :loading="loadingOcup"
                      hide-details
                      outlined
                      dense
                    />
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closedTransfer">Close</v-btn>
                  <v-btn
                    :disabled="
                      employeeUuidt == '' ||
                      employeeUuidt == null ||
                      relation.ocupationId == null ||
                      loadingAction
                    "
                    color="primary"
                    :loading="loadingAction"
                    text
                    @click="transfer"
                    >Confirm</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
        <template v-if="selected.length != 0">
          <v-dialog
            persistent
            v-model="deleteDialog"
            transition="dialog-top-transition"
            max-width="600"
          >
            <template>
              <v-card>
                <v-toolbar color="error" dark>Select Employees</v-toolbar>
                <v-card-text>
                  <div class="px-4">
                    <v-switch
                      inset
                      v-model="selectedAllEmpleDel"
                      label="Select All"
                    ></v-switch>
                  </div>
                  <div class="text-h2 px-4 d-flex">
                    <v-autocomplete
                      v-model="employeeUuidDel"
                      label="Select Agents"
                      :items="workers"
                      prepend-inner-icon="mdi-doctor"
                      item-text="fullname"
                      item-value="uuid"
                      :loading="loadingOcup"
                      hide-details
                      multiple
                      clearable
                      @click:clear="selectedAllEmpleDel = false"
                      outlined
                      dense
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          class="ma-1"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click:close="removeSel(data.item)"
                        >
                          {{ data.item.fullname }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.fullname"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.rol"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closedTransfer">Close</v-btn>
                  <v-btn
                    :disabled="employeeUuidDel.length == 0 || loadingAction"
                    color="error"
                    :loading="loadingAction"
                    text
                    @click="deleteRelation"
                    >Delete</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
        <template v-if="selected.length != 0">
          <asign-status
            :openDialog="openDialog"
            :clientsUuids="selected"
            @updated="toGePAtient($event)"
          />
          <asign-noms
            :openDialog="openDialogNom"
            :nomName="nomName"
            :clientsUuids="selected"
            @updated="toGePAtient($event)"
            :tags="clientsTags"
            :paysTo="paysTo"
            :paymentStatus="paymentStatus"
            :npns="npns"
            :renewalStatus="renewalStatus"
          />
          <v-menu transition="slide-x-transition" bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="white" text v-bind="attrs" v-on="on">
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </template>

            <v-list rounded>
              <v-subheader>Options</v-subheader>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="item in itemsMenu"
                  :key="item.key"
                  @click="openOption(item)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>

        <v-btn :loading="loading" text @click="getPatients">
          <v-icon>mdi-autorenew</v-icon>
          {{ $t("labelUpdate") }}
        </v-btn>
      </v-toolbar>

      <div class="d-flex align-center pa-3">
        {{/* Filter */ }}
        <div class="col-sm-3">
          <v-text-field
            v-model.trim="query"
            :label="'Search'"
            hide-details
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            dense
          />
        </div>
        <div class="col-sm-3">
          <v-text-field
            dense
            outlined
            hide-details
            label="by Policy Number"
            prepend-inner-icon="mdi-magnify"
            v-model="polizeNumber"
            clearable
          ></v-text-field>
        </div>

        <!--  <v-select
          v-model="policyPlan"
          label="Filter by Metal Plan"
          :items="metalplan"
          prepend-inner-icon="mdi-magnify"
          item-text="label"
          item-value="value"
          class="ml-1"
          hide-details
          clearable
          outlined
          dense
          @change="getPatients"
        /> -->

        <div class="d-flex pt-4">
          <template v-if="filterDate == 'custom'">
            <ma-date-picker class="pt-2 mx-2" v-model="dateFrom" label="From" past />
            <ma-date-picker class="pt-2" v-model="dateTo" label="To" past />
          </template>
        </div>
        <v-spacer></v-spacer>
        <template>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" icon><v-icon>mdi-filter</v-icon></v-btn>
              </template>

              <v-list width="140" nav dense>
                <v-list-item
                  v-for="interval in intervals"
                  :key="interval.value"
                  link
                  @click="setIntervar(interval.value)"
                >
                  <v-list-item-title>{{ interval.label }}</v-list-item-title>
                </v-list-item>
                <v-divider />

                <v-list-item link @click="filterDate = 'custom'">
                  <v-list-item-title>Select</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="filterDate = 'clear'">
                  <v-list-item-title class="red--text">Clean</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template v-if="isAdmin || isSuper">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="donwloading"
                :disabled="donwloading"
                v-on="on"
                icon
                color="success"
                @click="gotoExport()"
                ><v-icon>mdi-file-excel</v-icon></v-btn
              >
            </template>
            <span>Export {{ getpatientN + "s" }}</span>
          </v-tooltip>
        </template>
        <v-dialog
          v-model="dialogExport"
          persistent
          :overlay="false"
          max-width="800px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title class="headline primary white--text"> Export </v-card-title>
            <v-card-text class="mt-3">
              <div class="text-center">
                <h3>Select the columns you want to export:</h3>
              </div>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Select All"
                    v-model="checkAll"
                    color="success"
                    :value="true"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="3" v-for="(check, i) in selectColumnExport" :key="i">
                  <v-switch
                    inset
                    :label="check == 'Company_State' ? 'Company State' : check"
                    v-model="checkToExport"
                    :value="check"
                  ></v-switch>
                </v-col>
              </v-row>
              <template v-if="donwloading">
                <v-alert dense outlined type="warning" class="text-center mt-4">
                  Exporting...Please wait!!
                </v-alert>
              </template>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn text :disabled="donwloading" color="error" @click="cancelExport()"
                >cancel</v-btn
              >
              <v-btn
                text
                :disabled="donwloading"
                :loading="donwloading"
                color="success"
                @click="exportClients()"
                >export</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon color="info" @click="gotoConfigure()"
                ><v-icon>mdi-account-cog</v-icon></v-btn
              >
            </template>
            <span>Columns Configure</span>
          </v-tooltip>
        </template>
        <v-btn color="primary" text @click="openFilters"
          ><v-icon>mdi-filter-variant</v-icon> Filters</v-btn
        >
        <v-dialog
          v-model="configureDialog"
          persistent
          :overlay="false"
          max-width="800px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title class="headline primary white--text"> Columns </v-card-title>
            <v-card-text class="mt-3">
              <div class="text-center">
                <h3>Select the columns you want to show:</h3>
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  <v-checkbox
                    label="Select All"
                    v-model="showAll"
                    color="success"
                    :value="true"
                  ></v-checkbox>
                </div>
              </div>
              <div class="d-flex flex-wrap mb-4">
                <v-chip
                  class="ma-2 chipsCols"
                  outlined
                  color="primary"
                  label
                  v-for="(header, i) in headersToChange"
                  :key="i"
                >
                  <div class="d-flex justify-space-between contentCols">
                    <span>{{ header.text }}</span>

                    <template v-if="header.show">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            color="success"
                            v-on="on"
                            class="ml-2"
                            right
                            @click="updateHeader(false, i)"
                          >
                            mdi-eye
                          </v-icon>
                        </template>
                        <span>Click to Hide</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            color="error"
                            v-on="on"
                            class="ml-2"
                            right
                            @click="updateHeader(true, i)"
                          >
                            mdi-eye-off
                          </v-icon>
                        </template>
                        <span>Click to Show</span>
                      </v-tooltip></template
                    >
                  </div>
                </v-chip>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn :disabled="loadingAction" color="info" text @click="defaultHeaders"
                >default</v-btn
              >
              <v-btn
                :disabled="loadingAction"
                :loading="loadingAction"
                text
                color="success"
                @click="setHeaders()"
                >confirm</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-divider />
      <template v-if="filterDate != 'clear'">
        <v-layout class="pa-4" column>
          <div class="mr-2 text-capitalize">
            {{ filterDate.replace("-", " ") }}
          </div>
          <v-layout column class="text-body-1 text--secondary">
            <div>
              From
              <span class="text-caption">{{ prettyDate(query1.date1) }}</span>
            </div>
            <div>
              To
              <span class="text-caption">{{ prettyDate(query1.date2) }}</span>
            </div>
          </v-layout>
        </v-layout>
        <v-divider></v-divider>
      </template>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalpatient"
        :items="patients"
        :show-select="isAdmins"
        :loading="disableWhileLoading"
        :footer-props="footerProps"
        item-key="uuid"
        @click:row="showDetails"
      >
        <template v-slot:[`item.fullname`]="{ item }">
          <span class="namepatient">{{ item.fullname }} </span>
        </template>
        <template v-slot:[`item.relations`]="{ item }">
          <template v-if="item.relations && item.relations.length != 0">
            <div class="d-flex flex-column">
              <div class="mr-1" v-for="(r, i) in item.relations" :key="r.id">
                {{ r.employee ? r.employee.fullname : "-"
                }}{{ i == item.relations.length - 1 ? "" : ", " }}
              </div>
            </div>
          </template>
          <template v-else> - </template>
        </template>

        <template v-slot:[`item.patientTagsNom`]="{ item }">
          <template v-if="item.patientTagsNom && item.patientTagsNom.length != 0">
            <div class="chipstag">
              <v-chip
                class="mr-1"
                v-for="itm in item.patientTagsNom"
                :key="itm.id"
                :color="colorShowT(itm)"
                dark
              >
                {{ itm.tagNom ? itm.tagNom.name : "-" }}
              </v-chip>
            </div>
          </template>
          <template v-else>-</template>
        </template>

        <template v-slot:[`item.company`]="{ item }">
          {{ item.company ? item.company.name : "-" }}
        </template>
        <template v-slot:[`item.quotedDate`]="{ item }">
          <template v-if="item.quotedDate">
            {{ getDate(item.quotedDate) }}
          </template>
          <template v-else>
            <v-icon color="error" dense>mdi-close</v-icon>
          </template>
        </template>
        <template v-slot:[`item.companyState`]="{ item }">
          {{ item.companyState ? item.companyState.name : "-" }}
        </template>
        <template v-slot:[`item.call`]="{ item }">
          <template v-if="item.calls.length == 0">
            <v-icon color="error">mdi-close</v-icon>
          </template>
          <template v-else><v-icon color="success">mdi-check</v-icon></template>
        </template>
        <template v-slot:[`item.totalMembers`]="{ item }">
          <div>
            {{ item.totalMembers ? item.totalMembers : 0 }}
          </div>
        </template>
        <template v-slot:[`item.effectiveDate`]="{ item }">
          <div>
            {{ formatDate(item.effectiveDate) }}
          </div>
        </template>
        <template v-slot:[`item.isMale`]="{ item }">
          <div>
            {{ item + ";asd" }}
          </div>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div>
            {{ item.email + "asda" }}
          </div>
        </template>
        <template v-slot:[`item.homePhone`]="{ item }">
          <template v-if="item.homePhone">
            <div class="d-flex">
              <div class="cursor-pointer" @click="makeCallV(item.homePhone)">
                {{ item.homePhone }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="copyPhone(item.homePhone)"
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Phone</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-else>-</template>
        </template>
        <template v-slot:[`item.polizeNumber`]="{ item }">
          <template v-if="item.polizeNumber">
            <div class="d-flex">
              <div>
                {{ item.polizeNumber }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="copyPolizeNumber(item.polizeNumber)"
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Policy Number</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-else>-</template>
        </template>
        <template v-slot:[`item.policyPlan`]="{ item }">
          <div
            class="font-weight-bold"
            v-bind:style="{ color: colorPolicy(item.policyPlan) }"
          >
            {{ item.policyPlan }}
          </div>
        </template>
        <template v-slot:[`item.agent`]="{ item }">
          {{ getAgent(item) }}
        </template>
        <template v-slot:[`item.sourceNom`]="{ item }">
          <span v-bind:style="{ color: colorShowS(item) }" class="font-weight-bold">{{
            item.sourceNom ? item.sourceNom.name : "-"
          }}</span>
        </template>
        <template v-slot:[`item.npnNom`]="{ item }">
          <template v-if="item.npnNom">
            <span v-bind:style="{ color: item.npnNom.color }" class="font-weight-bold">{{
              item.npnNom ? item.npnNom.name : "-"
            }}</span>
          </template>
        </template>
        <template v-slot:[`item.payToNom`]="{ item }">
          <template v-if="item.payToNom">
            <span
              v-bind:style="{ color: item.payToNom.color }"
              class="font-weight-bold"
              >{{ item.payToNom ? item.payToNom.name : "-" }}</span
            >
          </template>
        </template>
        <template v-slot:[`item.renewalStatusNom`]="{ item }">
          <template v-if="item.renewalStatusNom">
            <span
              v-bind:style="{ color: item.renewalStatusNom.color }"
              class="font-weight-bold"
              >{{ item.renewalStatusNom ? item.renewalStatusNom.name : "-" }}</span
            >
          </template>
        </template>
        <template v-slot:[`item.paymentStatusNom`]="{ item }">
          <template v-if="item.paymentStatusNom">
            <span
              v-bind:style="{ color: item.paymentStatusNom.color }"
              class="font-weight-bold"
              >{{ item.paymentStatusNom ? item.paymentStatusNom.name : "-" }}</span
            >
          </template>
        </template>
        <template v-slot:[`item.polizeStatus`]="{ item }">
          <v-menu bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="text-body-1 cursor-pointer"
                v-bind:style="{ color: colorShow(item) }"
              >
                {{ statusShow(item) }}
              </div>
            </template>
            <v-list>
              <v-list-item
                class="cursor-pointer"
                v-for="(stat, index) in policyStates"
                :key="index"
                @click="gotosetStatus(item, stat)"
                :disabled="item.polizeStatus == stat.value"
              >
                <v-list-item-title
                  :class="item.polizeStatus == stat.value ? 'status-active' : ''"
                  >{{ stat.label }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:[`header.totalMembers`]="{ header }">
          {{ `${header.text}(${totalMembers})` }}
        </template>
        <template v-slot:[`header.polizeStatus`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="success" @click="showStatusSummary">mdi-eye</v-icon>
        </template>
        <template v-slot:[`header.paymentStatusNom`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="primary" @click="showSPaymentstatusSummary">mdi-eye</v-icon>
        </template>
        <template v-slot:[`header.payToNom`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="error" @click="showPayToSummary">mdi-eye</v-icon>
        </template>
        <template v-slot:[`header.patientTagsNom`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="error" @click="showTagSummary">mdi-eye</v-icon>
        </template>
        <template v-slot:[`header.npnNom`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="primary" @click="showNpnSummary">mdi-eye</v-icon>
        </template>
        <template v-slot:[`header.renewalStatusNom`]="{ header }">
          <template v-if="header">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on"> {{ `OP Status` }}</span>
              </template>
              <span>OP Status</span>
            </v-tooltip>
            <v-icon color="warning" @click="showRenewalStatusSummary">mdi-eye</v-icon>
          </template>
        </template>
        <template v-slot:[`header.sourceNom`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="info" @click="showSourceSummary">mdi-eye</v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.quotedDate">
            <v-tooltip transition="fade-transition" color="info" top>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon v-on="on"
                  ><v-icon>mdi-currency-usd</v-icon></v-btn
                >
              </template>

              <v-row class="toolt" no-gutters>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Quoted Date</label>
                  <span>{{ item.quotedDate ? getDate(item.quotedDate) : "-" }}</span>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Company</label>
                  <span>{{ item.company.name }}</span>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Members</label>
                  <span class="px-1">{{ item.members }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4 toolt">
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Metal Plan</label>

                  <span
                    class="text-body-1 font-weight-bold"
                    v-bind:style="{
                      color: colorPolicy(item.policyPlan),
                    }"
                    >{{ item.policyPlan }}</span
                  >
                </v-col>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Premium</label>
                  <span>{{ item.premium | currency }}</span>
                </v-col>
              </v-row>
            </v-tooltip>
          </template>
        </template>
        <not-records slot="no-data" />
      </v-data-table>
      <v-dialog
        v-model="setStatusObject.dialog"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="primary white--text">
            <template v-if="setStatusObject.value && setStatusObject.item">
              Status Change:
              {{
                statusShow(setStatusObject.item) + " to " + setStatusObject.value.label
              }}
            </template>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model.trim="setStatusObject.note"
              class="my-3"
              placeholder="Write a note"
              :disabled="loading"
              outlined
              counter="240"
            />
          </v-card-text>
          <template v-if="!validNote">
            <div class="mx-3">
              <v-alert dense outlined type="error">
                The note must be between 5 and 240 characters
              </v-alert>
            </div>
          </template>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="error"
              :disabled="setStatusObject.loading"
              text
              @click="cancelSetStatus"
              >cancel</v-btn
            >
            <v-btn
              color="success"
              :loading="setStatusObject.loading"
              :disabled="setStatusObject.loading || !validNote"
              text
              @click="setStatus"
              >Change</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showStatus.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <template>
            <v-data-table
              :headers="showStatus.headers"
              :items="showStatus.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.count_members`]="{ header }">
                {{ `${header.text} (${showStatus.totalmembers}) ` }}</template
              >
              <template v-slot:[`header.count_policies`]="{ header }">
                {{ `${header.text} (${showStatus.totalpolicies})` }}</template
              >
              <template v-slot:[`item.status`]="{ item }">
                <div
                  class="text-body-1 cursor-pointer"
                  v-bind:style="{ color: colorShow(item) }"
                >
                  {{ statusShow(item) }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showPaymentStatus.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <template>
            <v-data-table
              :headers="showPaymentStatus.headers"
              :items="showPaymentStatus.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.count_members`]="{ header }">
                {{ `${header.text} (${showPaymentStatus.totalmembers}) ` }}</template
              >
              <template v-slot:[`header.count_policies`]="{ header }">
                {{ `${header.text} (${showPaymentStatus.totalpolicies})` }}</template
              >
              <template v-slot:[`item.status`]="{ item }">
                <div
                  class="text-body-1 cursor-pointer"
                  v-bind:style="{ color: getColorPaymentStatus(item) }"
                >
                  {{ item.paymentstatus ? item.paymentstatus : "No Payment Status" }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showNpn.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <template>
            <v-data-table
              :headers="showNpn.headers"
              :items="showNpn.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.count_members`]="{ header }">
                {{ `${header.text} (${showNpn.totalmembers}) ` }}</template
              >
              <template v-slot:[`header.count_policies`]="{ header }">
                {{ `${header.text} (${showNpn.totalpolicies})` }}</template
              >
              <template v-slot:[`item.npn`]="{ item }">
                <div
                  class="text-body-1 cursor-pointer"
                  v-bind:style="{ color: getColorNpn(item) }"
                >
                  {{ item.npn ? item.npn : "No Npn" }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showPayTo.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <template>
            <v-data-table
              :headers="showPayTo.headers"
              :items="showPayTo.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.count_members`]="{ header }">
                {{ `${header.text} (${showPayTo.totalmembers}) ` }}</template
              >
              <template v-slot:[`header.count_policies`]="{ header }">
                {{ `${header.text} (${showPayTo.totalpolicies})` }}</template
              >
              <template v-slot:[`item.payto`]="{ item }">
                <div
                  class="text-body-1 cursor-pointer"
                  v-bind:style="{ color: getColorPayTo(item) }"
                >
                  {{ item.payto ? item.payto : "No Pay To" }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showTag.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <template>
            <v-data-table
              :headers="showTag.headers"
              :items="showTag.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.count_members`]="{ header }">
                {{ `${header.text} (${showTag.totalmembers}) ` }}</template
              >
              <template v-slot:[`header.count_policies`]="{ header }">
                {{ `${header.text} (${showTag.totalpolicies})` }}</template
              >
              <template v-slot:[`item.tagnom`]="{ item }">
                <div
                  class="text-body-1 cursor-pointer"
                  v-bind:style="{ color: getColorTag(item) }"
                >
                  {{ item.tagnom ? item.tagnom : "No Tag" }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showRenewalStatus.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <template>
            <v-data-table
              :headers="showRenewalStatus.headers"
              :items="showRenewalStatus.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.count_members`]="{ header }">
                {{ `${header.text} (${showRenewalStatus.totalmembers}) ` }}</template
              >
              <template v-slot:[`header.count_policies`]="{ header }">
                {{ `${header.text} (${showRenewalStatus.totalpolicies})` }}</template
              >
              <template v-slot:[`item.renewalstatus`]="{ item }">
                <div
                  :class="item.renewalstatus == null ? 'text-decoration-underline' : ''"
                  class="text-body-1 cursor-pointer"
                  v-bind:style="{ color: getColorRStatus(item) }"
                >
                  {{ item.renewalstatus ? item.renewalstatus : "No OP Status" }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showSource.dialog"
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <template>
            <v-data-table
              :headers="showSource.headers"
              :items="showSource.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`header.count_members`]="{ header }">
                {{ `${header.text} (${showSource.totalmembers}) ` }}</template
              >
              <template v-slot:[`header.count_policies`]="{ header }">
                {{ `${header.text} (${showSource.totalpolicies})` }}</template
              >

              <template v-slot:[`item.source`]="{ item }">
                <div
                  :class="item.source == null ? 'text-decoration-underline' : ''"
                  class="text-body-1 cursor-pointer"
                  v-bind:style="{ color: colorSourceEye(item) }"
                >
                  {{ item.source ? item.source : "No Source" }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-layout justify-center>
        <errors v-model="showErrors" class="mt-3" :message="errorMessage" />
      </v-layout>
    </v-card>
    <template v-if="filterExpand">
      <v-navigation-drawer v-model="filterExpand" app v-cloak>
        <div class="d-flex pa-2">
          <span class="py-1">Filters</span><v-spacer></v-spacer
          ><v-btn text @click="closeFilters"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider></v-divider>
        <div class="d-flex flex-column">
          <div class="col-sm-12">
            <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              hide-details
              clearable
              outlined
              @change="getPatients()"
              dense
              :items="references"
              item-text="name"
              item-value="id"
              v-model="sourceId"
              label="by Source"
            >
              <!--  <template v-slot:prepend-item>
              <v-text-field
                class="px-2"
                prepend-inner-icon="mdi-magnify-plus-outline"
                placeholder="Custom source"
                clearable
                @click:clearable="getPatients()"
                @change="getPatients()"
                v-model="sourceId"
              ></v-text-field>
            </template> -->
              <!--   <template v-slot:selection>
                <span>{{ body.howKnow }}</span>
              </template> -->
            </v-autocomplete>
          </div>
          <v-divider></v-divider>
          <template v-if="isAdmins">
            <div class="col-sm-12">
              <v-autocomplete
                v-model="agentUuid"
                label="by Agent"
                :items="workers"
                class="mx-1"
                prepend-inner-icon="mdi-doctor"
                item-text="fullname"
                item-value="uuid"
                :loading="loading"
                hide-details
                clearable
                outlined
                dense
                @change="getPatients"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="data.item.ocupation"
                      class="text-capitalize"
                      v-html="data.item.ocupation.name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </div>
            <v-divider></v-divider>
          </template>

          <div class="col-sm-12">
            <v-select
              v-model="companyId"
              label="by Company"
              :items="companies"
              prepend-inner-icon="mdi-briefcase-variant-outline"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              @change="getPatients"
            />
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-select
              v-model="companyStateId"
              label="by Company State"
              :items="states"
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              :loading="loadingStates"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              @change="getPatients"
            />
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-select
              v-model="npnNomIds"
              label="by Npns"
              :items="npns"
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              item-value="id"
              multiple
              hide-details
              clearable
              outlined
              dense
              ><template v-slot:prepend-inner>
                <template v-if="!checkAllNpns">
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          @click="checkAllNpns = true"
                          v-text="'mdi-check-all'"
                        ></v-icon>
                      </template>
                      <span>Select All Npn</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </template>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ npnNomIds.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-select
              v-model="polizeStatus"
              label="by Status"
              :items="statesf"
              prepend-inner-icon="mdi-magnify"
              item-text="label"
              item-value="value"
              hide-details
              clearable
              outlined
              dense
              @change="getPatients"
            />
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-select
              v-model="renewalStatusNomId"
              label="by OP Status"
              :items="renewalStatus"
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              @change="getPatients"
            />
          </div>
          <template v-if="isAdmins">
            <v-divider></v-divider>
            <div class="col-sm-12">
              <v-select
                v-model="payToNomIds"
                label="by Pay to"
                prepend-inner-icon="mdi-magnify"
                :items="paysTo"
                item-text="name"
                item-value="id"
                multiple
                hide-details
                clearable
                outlined
                dense
              >
                <template v-slot:prepend-inner>
                  <template v-if="!checkAllPaysTo">
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="success"
                            @click="checkAllPaysTo = true"
                            v-text="'mdi-check-all'"
                          ></v-icon>
                        </template>
                        <span>Select All Pay To</span>
                      </v-tooltip>
                    </v-slide-x-reverse-transition>
                  </template>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ payToNomIds.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>
          </template>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-select
              v-model="paymentStatusNomIds"
              label="by Payment Status"
              prepend-inner-icon="mdi-magnify"
              :items="paymentStatus"
              item-text="name"
              item-value="id"
              multiple
              hide-details
              clearable
              outlined
              dense
            >
              <template v-slot:prepend-inner>
                <template v-if="!checkAllPaymentStatus">
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          @click="checkAllPaymentStatus = true"
                          v-text="'mdi-check-all'"
                        ></v-icon>
                      </template>
                      <span>Select All Payment Status</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </template>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ paymentStatusNomIds.length - 1 }} others)
                </span>
              </template>
            </v-select>
            <div
              class="d-flex mt-1"
              v-if="paymentStatusNomIds != null && paymentStatusNomIds.length != 0"
            >
              <v-btn color="success" text x-small @click="excludeFilters(false)"
                >Include</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="error" text x-small @click="excludeFilters(true)"
                >Exclude</v-btn
              >
            </div>
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-autocomplete
              v-model="tagId"
              label="by Tags"
              :items="clientsTags"
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              item-value="id"
              multiple
              hide-details
              clearable
              outlined
              dense
            >
              <template v-slot:prepend-inner>
                <template v-if="!checkAllTags">
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          @click="checkAllTags = true"
                          v-text="'mdi-check-all'"
                        ></v-icon>
                      </template>
                      <span>Select All Tags</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </template>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ tagId.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </div>
          <v-divider></v-divider>
          <div class="col-sm-12">
            <v-row no-gutters>
              <div class="col-sm-12">
                <span class="mt-1">Effective Date</span> <v-spacer></v-spacer>
                <div></div>
              </div>
              <div class="col-sm-12 d-flex">
                <month-picker
                  ref="mtp"
                  :inFilter="true"
                  :isMultiple="true"
                  @monthPick="effectiveDate = $event"
                />
              </div>
              <!--  <div class="col-sm-12"><month-picker :inFilter="true"/></div> -->
            </v-row>
          </div>
          <v-divider></v-divider>

          <div class="col-sm-12 d-flex justify-end">
            <v-btn color="error" @click="clearFilters">Clear filters</v-btn>
          </div>
        </div>
      </v-navigation-drawer>
    </template>

    {{/* Confirm remove */ }}
  </v-layout>
</template>

<script>
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import Errors from "../PdfSign/Errors.vue";

import BtnRequestPatients from "./buttons/BtnRequestPatients.vue";

import { PolicyStatus } from "@/utils/enums/policystatus";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import cleanObject from "@/utils/cleanObject";
import AsignStatus from "@/views/Patients/components/AsignStatus.vue";
import AsignNoms from "@/views/Patients/components/AsignNoms.vue";
import { funtionsPhone, utilMixin } from "@/utils/mixins";
import { cleanPhoneNumber, isPhoneNumber } from "@/utils/formatPhone";
import MonthPicker from "@/components/MaDatePicker/MonthPicker.vue";

export default Vue.extend({
  name: "crm-patients",
  components: {
    NotRecords,
    Errors,
    BtnRequestPatients,
    MaDatePicker,
    AsignStatus,
    AsignNoms,
    MonthPicker,
    /*   PatientFilter, */
  },
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      dateTo: null,
      dateFrom: null,
      filterDate: "clear",
      transferDialog: false,
      deleteDialog: false,
      dialogExport: false,
      checkToExport: [],
      showStatus: {
        dialog: false,
        data: [],
        totalpolicies: 0,
        totalmembers: 0,
        headers: [
          { text: "Status", value: "status", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
          { text: "Members", value: "count_members", sortable: false },
        ],
      },
      showPaymentStatus: {
        dialog: false,
        data: [],
        totalpolicies: 0,
        totalmembers: 0,
        headers: [
          { text: "Payment Status", value: "status", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
          { text: "Members", value: "count_members", sortable: false },
        ],
      },
      showNpn: {
        dialog: false,
        data: [],
        totalpolicies: 0,
        totalmembers: 0,
        headers: [
          { text: "Npn", value: "npn", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
          { text: "Members", value: "count_members", sortable: false },
        ],
      },
      showPayTo: {
        dialog: false,
        data: [],
        totalpolicies: 0,
        totalmembers: 0,
        headers: [
          { text: "Pay To", value: "payto", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
          { text: "Members", value: "count_members", sortable: false },
        ],
      },
      showTag: {
        dialog: false,
        data: [],
        totalpolicies: 0,
        totalmembers: 0,
        headers: [
          { text: "Tag", value: "tagnom", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
          { text: "Members", value: "count_members", sortable: false },
        ],
      },
      showRenewalStatus: {
        dialog: false,
        data: [],
        totalpolicies: 0,
        totalmembers: 0,
        headers: [
          { text: "OP Status", value: "renewalstatus", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
          { text: "Members", value: "count_members", sortable: false },
        ],
      },
      showSource: {
        dialog: false,
        data: [],
        totalpolicies: 0,
        totalmembers: 0,
        headers: [
          { text: "Source", value: "source", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
          { text: "Members", value: "count_members", sortable: false },
        ],
      },
      patients: [],
      totalpatient: 0,
      loading: false,
      checkAll: false,
      showAll: false,
      selectColumnExport: [
        "Name",
        "Last Name",
        "DOB",
        "Address",
        "Company",
        "MemberID",
        "Seller",
        "Notes",
        "Payments",
        "Members",
        "Phone",
        "Company_State",
      ],
      loadingOcup: false,
      loadingAction: false,
      ocupations: [],
      lang: [
        { label: "All", value: null },
        { label: "English", value: "ENGLISH" },
        { label: "Spanish", value: "SPANISH" },
      ],
      selectedItem: null,
      nomName: null,
      filterExpand: false,
      employeeUuidt: "",
      employeeUuidDel: [],
      openDialog: false,
      openDialogNom: false,
      selectedAllEmpleDel: false,
      relation: {
        employeeUuid: "",
        ocupationId: null,
        patientUuids: [],
      },
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
      headersToChange: [],
      signature: new Blob(),
      loadingSignStep1: false,
      showErrors: false,
      options: {
        sortDesc: [false],
      },
      reqrange: {
        limit: 100,
        offset: 0,
      },
      errorMessage: "",

      loadingSendStep: false,
      footerProps: {
        "items-per-page-options": [15, 50, 100, 500],
      },
      selected: [],
      expanded: [],
      // The uuid of the worker to remove
      currentPatient: null,
      dialogEdit: false,
      workers: [],
      dialogDocument: false,
      dialogSignStep1: false,
      loadingState: false,
      query1: {
        date1: "",
        date2: "",
      },
      toExport: null,
      donwloading: false,
      loadingRenewalS: false,
      renewalStatus: [],
      npnloading: false,
      npns: [],
      paytoloading: false,
      paysTo: [],
      paymentStatus: [],
      query: null,
      totalMembers: 0,
      totalPolicies: 0,
      loadingSummary: false,
      polizeStatus: null,
      renewalStatusNomId: null,
      policyPlan: null,
      polizeNumber: null,
      preferLanguage: null,
      companyStateId: null,
      companyId: null,
      sourceId: null,
      tagId: null,
      cleaning: false,
      checkAllTags: false,
      checkAllNpns: false,
      checkAllPaysTo: false,
      checkAllPaymentStatus: false,
      effectiveDate: [],
      npnNomIds: null,
      paymentStatusNomIds: null,
      noPaymentStatusNomIds: null,
      payToNomIds: null,
      noCoordinator: false,
      sortAscending: false,
      employeeUuid: null,
      agentUuid: null,
      cotizedOnly: false,
      createdInterval: {
        date1: "",
        date2: "",
      },
      loadingStatus: false,
      setStatusObject: {
        dialog: false,
        value: null,
        item: null,
        loading: false,
        note: "",
      },
      isAdmins: false,

      statesf: [
        { value: "POLICY_NEW", label: "Policy New" },
        { value: "POLIZE_PENDING", label: "Policy Pending" },
        { value: "POLIZE_OK", label: "Policy Ok" },
        {
          value: "POLIZE_MEMBERS_NO_MATCH",
          label: "Policy Member does not match",
        },
        {
          value: "PAYMENT_MEMBERS_NO_MATCH",
          label: "Member payment does not match",
        },
        { value: "PAYMENT_OK", label: "Payment Ok" },
        { value: "PAYMENT_PENDING", label: "Payment Pending" },
        { value: "POLICY_CANCELLATION", label: "Cancelled" },
        { value: "POLICY_RENEWAL", label: "Renewal" },
        { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
        { value: "OTHER_PARTY", label: "Other Party" },
        { value: "NO_AOR", label: "No Aor" },
        { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
        { value: "DELINCUENT", label: "Delinquent" },
        { value: "POLICY_BLACKLIST", label: "Blacklist" },
        { value: "CHARGE_BACK", label: "Charge Back" },
        { value: "PENDING_RENEWAL", label: "Pending Renewal" },
        { value: "NO_RENEWAL", label: "No Renewal" },
        { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
      ],
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],

      configureDialog: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["doctors", "agents", "states", "loadingStates"]),
    ...mapGetters("crmMedicFormModule", ["getHeaders", "getHeadersShow"]),

    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "isSupervisor",
      "getCoordinatorN",
      "getSellerN",
      "getpatientN",
      "getviewpatientStatus",
    ]),
    ...mapState(["dataCRM", "configId"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "companies", "references", "tags"]),

    clientsTags() {
      let lt = this.tags.sort((a, b) => {
        return a.isLead - b.isLead;
      });
      lt = lt.filter((r) => r.status == "ACTIVE");
      return lt;
    },
    itemsMenu() {
      return this.isAdmins
        ? [
            {
              title: "Create Relationship",
              icon: "mdi-account-network",
              key: "relations",
            },
            {
              title: "Delete Relationship",
              icon: "mdi-close-network",
              key: "drelations",
            },
            { title: "Manage Status", icon: "mdi-shuffle-variant", key: "status" },
            { title: "Manage Tags", icon: "mdi-marker-check", key: "Tags" },
            { title: "Manage Npn", icon: "mdi-currency-ngn", key: "Npn" },
            {
              title: "Manage OP Status",
              icon: "mdi-shuffle",
              key: "OP Status",
            },
            { title: "Manage Pay to", icon: "mdi-currency-usd", key: "Pay To" },
            /*  {
              title: "Manage Payment Status",
              icon: "mdi-currency-usd",
              key: "Payment Status",
            }, */
          ]
        : [
            {
              title: "Create Relationship",
              icon: "mdi-account-network",
              key: "relations",
            },
            {
              title: "Delete Relationship",
              icon: "mdi-close-network",
              key: "drelations",
            },
            { title: "Manage Status", icon: "mdi-shuffle-variant", key: "status" },
            { title: "Manage Tags", icon: "mdi-marker-check", key: "Tags" },
            { title: "Manage Npn", icon: "mdi-currency-ngn", key: "Npn" },
            {
              title: "Manage OP Status",
              icon: "mdi-shuffle",
              key: "OP Status",
            },
            /*   {
              title: "Manage Payment Status",
              icon: "mdi-currency-usd",
              key: "Payment Status",
            }, */
          ];
    },

    validNote() {
      if (
        this.setStatusObject.note == "" ||
        this.setStatusObject.note == null ||
        this.setStatusObject.note == undefined
      ) {
        return false;
      }
      if (
        this.setStatusObject.note.length < 5 ||
        this.setStatusObject.note.length > 240
      ) {
        return false;
      }
      return true;
    },

    disableWhileLoading() {
      return this.loading || this.loadingSignStep1 || this.loadingSendStep;
    },

    policyStates() {
      const statesf = this.isAdmins
        ? [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ]
        : [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },

            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ];
      return statesf;
    },

    headers() {
      let x = this.getHeadersShow;
      if (!this.isAdmins) {
        x = x.filter((htc) => htc.text != "Pay To");
      }
      return x;

      /* return [
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("Company"), value: "company", sortable: false },
        { text: this.$t("Agent"), value: "agent", sortable: false },
       { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "start", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "start",
          sortable: false,
        },
      ];*/
    },

    intervalToday() {
      const from = moment().startOf("day");
      const to = moment();
      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").startOf("day");
      const to = moment().subtract(1, "days").endOf("day");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().startOf("week");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").startOf("week");
      const to = moment().subtract(1, "week").endOf("week");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().startOf("month");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment().subtract(1, "month").startOf("month");
      const to = moment().subtract(1, "month").endOf("month");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().startOf("year");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment().subtract(1, "year").startOf("year");
      const to = moment().subtract(1, "year").endOf("year");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
  },
  watch: {
    filterDate: {
      handler() {
        if (this.filterDate != "custom") {
          this.getPatients();
        }
      },
    },
    effectiveDate: {
      handler() {
        if (!this.cleaning) {
          this.getPatients();
        }
      },
    },

    checkAll(val) {
      if (val == true) {
        this.checkToExport = this.selectColumnExport;
      } else {
        this.checkToExport = [];
      }
    },
    showAll(val) {
      if (val == true) {
        this.setAll(true);
      } else {
        this.setAll(false);
      }
    },
    employeeUuidt(val) {
      if (val != "" && val != null) {
        const worker = this.workers.filter((w) => w.uuid == val)[0];
        this.relation.ocupationId = worker.ocupation ? worker.ocupation.id : null;
        this.relation.employeeUuid = val;
      } else {
        this.relation.ocupationId = null;
        this.relation.employeeUuid = "";
      }
    },
    options(val) {
      if (val != {}) {
        if (val.sortDesc[0] != undefined) {
          this.sortAscending = val.sortDesc[0];
          this.getPatients();
        }
      }
    },
    query(val) {
      if (val == null) {
        this.getPatients();
      } else if (val.length >= 3 || val.length == 0) {
        if (isPhoneNumber(val)) {
          this.query = cleanPhoneNumber(val);
          if (this.query[0] == "1") {
            this.query = this.query.slice(1);
          }
        }
        this.getPatients();
      }
    },
    polizeNumber(val) {
      if (val == null) {
        this.getPatients();
      } else if (val.length >= 5 || val.length == 0) {
        this.getPatients();
      }
    },

    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.getPatients();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getPatients();
      }
    },
    selectedAllEmpleDel(val) {
      if (val) {
        this.employeeUuidDel = this.workers.map((w) => {
          return { ...w };
        });
      } else {
        this.employeeUuidDel = [];
      }
    },
    tagId: {
      handler(val) {
        if (!this.cleaning) {
          this.getPatients();
        }

        if (val && val.length != this.clientsTags.length) {
          this.checkAllTags = false;
        } else if (
          val &&
          val.length == this.clientsTags.length &&
          this.checkAllTags == false
        ) {
          this.checkAllTags = true;
        }
      },
    },
    npnNomIds: {
      handler(val) {
        if (!this.cleaning) {
          this.getPatients();
        }
        if (val && val.length != this.npns.length) {
          this.checkAllNpns = false;
        } else if (val && val.length == this.npns.length && this.checkAllNpns == false) {
          this.checkAllNpns = true;
        }
      },
    },
    payToNomIds: {
      handler(val) {
        if (!this.cleaning) {
          this.getPatients();
        }
        if (val && val.length != this.paysTo.length) {
          this.checkAllPaysTo = false;
        } else if (
          val &&
          val.length == this.paysTo.length &&
          this.checkAllPaysTo == false
        ) {
          this.checkAllPaysTo = true;
        }
      },
    },
    paymentStatusNomIds: {
      handler(val) {
        if (!this.cleaning && (val == null || val.length == 0)) {
          this.getPatients();
        }
        if (val && val.length != this.paymentStatus.length) {
          this.checkAllPaymentStatus = false;
        } else if (
          val &&
          val.length == this.paymentStatus.length &&
          this.checkAllPaymentStatus == false
        ) {
          this.checkAllPaymentStatus = true;
        }
      },
    },

    checkAllTags(val) {
      if (val) {
        if (this.tagId == null || this.clientsTags.length != this.tagId.length) {
          this.tagId = this.clientsTags.map((t) => {
            return t.id;
          });
        }
      }
    },
    checkAllNpns(val) {
      if (val) {
        if (this.npnNomIds == null || this.npns.length != this.npnNomIds.length) {
          this.npnNomIds = this.npns.map((t) => {
            return t.id;
          });
        }
      }
    },
    checkAllPaysTo(val) {
      if (val) {
        if (this.payToNomIds == null || this.paysTo.length != this.payToNomIds.length) {
          this.payToNomIds = this.paysTo.map((t) => {
            return t.id;
          });
        }
      }
    },
    checkAllPaymentStatus(val) {
      if (val) {
        if (
          this.paymentStatusNomIds == null ||
          this.paymentStatus.length != this.paymentStatusNomIds.length
        ) {
          this.paymentStatusNomIds = this.paymentStatus.map((t) => {
            return t.id;
          });
        }
      }
    },
  },
  destroyed() {
    localStorage.removeItem("filterfrompatients");
    this.closeFilters();
  },
  async mounted() {
    localStorage.removeItem("tab-patient");

    this.mutHeaders(this.dataCRM.columnsClients);

    this.actListCompaniesNom();
    this.actListReferences();
    this.actListTags();
    this.getRenewalStatus();
    this.listNpns();
    this.listPaymentStatus();
    await this.actListStates();

    await this.getEmployees();

    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
      this.listPayto();
      this.loadingOcup = true;
      await this.listOcupations();
      this.loadingOcup = false;
    }
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actFilterPatient", "actListStates"]),
    ...mapMutations("crmMedicFormModule", ["mutHeaders"]),
    ...mapActions(["actUpdateSettings"]),

    ...mapActions("crmSettingsModule", [
      "actListCompaniesNom",
      "actListReferences",
      "actListTags",
    ]),
    ...mapMutations([
      "mutReqRange",
      "mutFilterDrawerExpand",
      "mutDrawerExpand",
      "mutDataCRMColumnsClients",
    ]),
    setAll(val) {
      this.headersToChange.forEach((element) => {
        element.show = val;
      });
    },

    openFilters() {
      this.filterExpand = !this.filterExpand;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },
    closeFilters() {
      this.filterExpand = false;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },
    removeSel(item) {
      const f = this.employeeUuidDel.find((e) => e.uuid == item.uuid);

      const index = this.employeeUuidDel.indexOf(f);

      if (index >= 0) this.employeeUuidDel.splice(index, 1);
    },
    openOption(item) {
      this.nomName = item.key;
      switch (item.key) {
        case "relations":
          this.transferDialog = true;
          break;
        case "drelations":
          this.deleteDialog = true;
          break;
        case "status":
          this.openDialog = true;
          break;
        case "Tags":
          this.openDialogNom = true;
          break;
        case "Npn":
          this.openDialogNom = true;
          break;
        case "OP Status":
          this.openDialogNom = true;
          break;
        case "Pay To":
          this.openDialogNom = true;
          break;
        case "Payment Status":
          this.openDialogNom = true;
          break;
      }
    },
    setHeaders() {
      if (!this.isAdmins) {
        this.headersToChange = this.headersToChange.filter((htc) => htc.text != "Pay To");
      }
      this.mutHeaders(this.headersToChange);
      this.mutDataCRMColumnsClients(this.headersToChange);
      this.saveSettings();
    },
    gotoConfigure() {
      this.headersToChange = this.getHeaders.map((ele) => {
        return ele;
      });
      if (!this.isAdmins) {
        this.headersToChange = this.headersToChange.filter((htc) => htc.text != "Pay To");
      }
      this.configureDialog = true;
    },

    updateHeader(value, index) {
      this.headersToChange[index].show = value;
    },
    defaultHeaders() {
      this.headersToChange = [
        { text: "Fullname", show: true, width: 200, value: "fullname", sortable: true },
        {
          text: "Policy Number",
          show: false,
          value: "polizeNumber",
          sortable: true,
        },
        { text: "Phone", show: true, value: "homePhone", sortable: true },
        {
          text: "Agent",
          show: true,
          value: "relations",
          width: 200,
          sortable: false,
        },

        { text: "Company", show: true, width: 200, value: "company", sortable: false },
        {
          text: "Company State",
          width: 200,
          show: true,
          value: "companyState",
          sortable: false,
        },
        /*   { text: "Language", show: false, value: "preferlangu", sortable: false }, */
        { text: "Source", show: true, width: 200, value: "sourceNom", sortable: false },

        {
          text: "Quote Date",
          show: false,
          value: "quotedDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Members",
          show: true,
          value: "totalMembers",
          align: "center",
          sortable: false,
        },
        {
          text: "Npn",
          show: false,
          width: 200,
          value: "npnNom",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          show: true,
          width: 200,
          value: "polizeStatus",
          sortable: false,
        },
        { text: "With Check", show: false, value: "call", sortable: false },
        { text: "Quote", show: false, value: "actions", sortable: false },
        {
          text: "OP Status",
          show: false,
          value: "renewalStatusNom",
          width: 200,
          sortable: false,
        },
        {
          text: "Payment Status",
          show: false,
          value: "paymentStatusNom",
          width: 200,
          sortable: false,
        },
        { text: "Pay To", show: false, value: "payToNom", width: 200, sortable: false },
        { text: "Effective Date", show: false, value: "effectiveDate", sortable: false },
        { text: "Tags", show: true, value: "patientTagsNom", sortable: false },
        /* { text: this.$t("labelGender"),show: false, value: "isMale", sortable: false },
      { text: "Email", value: "email", show: false, align: "start", sortable: false },
      {
      text: this.$t("labelHomePhone"),
      value: "homePhone",show: false,
      align: "start",
      sortable: false,
      }, */
      ];
      if (!this.isAdmins) {
        this.headersToChange = this.headersToChange.filter((htc) => htc.text != "Pay To");
      }
      this.mutHeaders(this.headersToChange);
      this.mutDataCRMColumnsClients(this.headersToChange);
      this.saveSettings();
    },

    saveSettings() {
      const valor = JSON.stringify(this.dataCRM);
      this.loadingAction = true;
      const settings = {
        name: "general_config",
        description: "All configurations ",
        value: valor,
        isPublic: true,
        uuid: this.configId,
        active: true,
      };
      this.actUpdateSettings(settings)
        .then(() => {
          this.loadingAction = false;
          this.configureDialog = false;
          notifySuccess("Columns has been update");
        })
        .catch((error) => {
          this.loadingAction = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    gotoExport() {
      this.dialogExport = true;
      this.checkToExport = [];
      this.checkAll = false;
    },
    cancelExport() {
      this.dialogExport = false;
      this.checkToExport = [];
      this.checkAll = false;
    },

    async listOcupations() {
      this.ocupations = (await getAPI(`/ocupation-nom/list`)).data;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    setIntervar(value) {
      this.filterDate = value;
    },

    getEmployees() {
      getAPI("/users/listUsers").then((res) => {
        this.workers = res.data;
      });
    },
    toGePAtient(value) {
      this.openDialog = false;
      this.openDialogNom = false;
      this.selectedItem = null;
      this.nomName = null;
      if (value) {
        this.polizeStatus = null;
        this.getPatients();
      }
    },

    /* summary */

    showStatusSummary() {
      this.showStatus.dialog = true;
    },
    showSPaymentstatusSummary() {
      this.showPaymentStatus.dialog = true;
    },
    showPayToSummary() {
      this.showPayTo.dialog = true;
    },
    showTagSummary() {
      this.showTag.dialog = true;
    },
    showNpnSummary() {
      this.showNpn.dialog = true;
    },

    showRenewalStatusSummary() {
      this.showRenewalStatus.dialog = true;
    },
    showSourceSummary() {
      this.showSource.dialog = true;
    },
    getSummary(value) {
      this.loadingSummary = true;
      delete value.range;
      this.showSource.totalmembers = 0;
      this.showSource.totalpolicies = 0;
      this.showRenewalStatus.totalmembers = 0;
      this.showRenewalStatus.totalpolicies = 0;
      this.showStatus.totalmembers = 0;
      this.showStatus.totalpolicies = 0;
      this.showNpn.totalmembers = 0;
      this.showNpn.totalpolicies = 0;
      this.showPayTo.totalmembers = 0;
      this.showPayTo.totalpolicies = 0;
      this.showTag.totalmembers = 0;
      this.showTag.totalpolicies = 0;
      this.showPaymentStatus.totalmembers = 0;
      this.showPaymentStatus.totalpolicies = 0;

      getAPI
        .post("/patient/summaryFiltrPatient", value)
        .then((res) => {
          this.loadingSummary = false;
          const response = res.data;
          const t = response.find((r) => r.status == "TOTAL");
          const temp = response.filter((r) => r.status != "TOTAL");
          const status = temp.filter((r) => r.status != "UNKNOWN");
          const sources = temp.filter((r) => r.source != "UNKNOWN");
          const renewalStat = temp.filter((r) => r.renewalstatus != "UNKNOWN");
          const paysTo = temp.filter((r) => r.payto != "UNKNOWN");
          const npns = temp.filter((r) => r.npn != "UNKNOWN");
          const tagnoms = temp.filter((r) => r.tagnom != "UNKNOWN");
          const paymentStatus = temp.filter((r) => r.paymentstatus != "UNKNOWN");

          sources.forEach((so) => {
            if (so.source != null) {
              this.showSource.totalmembers =
                this.showSource.totalmembers + so.count_members;
              this.showSource.totalpolicies =
                this.showSource.totalpolicies + so.count_policies;
            }
          });

          renewalStat.forEach((rs) => {
            if (rs.renewalstatus != null) {
              this.showRenewalStatus.totalmembers =
                this.showRenewalStatus.totalmembers + rs.count_members;
              this.showRenewalStatus.totalpolicies =
                this.showRenewalStatus.totalpolicies + rs.count_policies;
            }
          });

          status.forEach((ss) => {
            if (ss.status != null) {
              this.showStatus.totalmembers =
                this.showStatus.totalmembers + ss.count_members;
              this.showStatus.totalpolicies =
                this.showStatus.totalpolicies + ss.count_policies;
            }
          });
          paymentStatus.forEach((ss) => {
            if (ss.paymentstatus != null) {
              this.showPaymentStatus.totalmembers =
                this.showPaymentStatus.totalmembers + ss.count_members;
              this.showPaymentStatus.totalpolicies =
                this.showPaymentStatus.totalpolicies + ss.count_policies;
            }
          });
          npns.forEach((ss) => {
            if (ss.npn != null) {
              this.showNpn.totalmembers = this.showNpn.totalmembers + ss.count_members;
              this.showNpn.totalpolicies = this.showNpn.totalpolicies + ss.count_policies;
            }
          });
          paysTo.forEach((ss) => {
            if (ss.payto != null) {
              this.showPayTo.totalmembers =
                this.showPayTo.totalmembers + ss.count_members;
              this.showPayTo.totalpolicies =
                this.showPayTo.totalpolicies + ss.count_policies;
            }
          });
          tagnoms.forEach((ss) => {
            if (ss.tagnom != null) {
              this.showTag.totalmembers = this.showTag.totalmembers + ss.count_members;
              this.showTag.totalpolicies = this.showTag.totalpolicies + ss.count_policies;
            }
          });

          this.showStatus.data = status;
          this.showPayTo.data = paysTo;
          this.showTag.data = tagnoms;
          this.showNpn.data = npns;
          this.showRenewalStatus.data = renewalStat;
          this.showPaymentStatus.data = paymentStatus;
          this.showSource.data = sources;
          this.totalMembers = t.count_members;
          this.totalPolicies = t.count_policies;
        })
        .catch((error) => {
          this.loadingSummary = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    excludeFilters(value) {
      if (value == true) {
        this.noPaymentStatusNomIds = this.paymentStatusNomIds.map((ps) => {
          return ps;
        });
      } else {
        this.noPaymentStatusNomIds = null;
      }
      this.getPatients();
    },

    getPatients() {
      this.selected = [];

      if (
        localStorage.getItem("filterfrompatients") &&
        localStorage.getItem("filtersPatients")
      ) {
        const tempdata = JSON.parse(localStorage.getItem("filtersPatients"));
        this.options.page = tempdata.page;
        this.options.itemsPerPage = tempdata.itemsPerPage;
        this.query = tempdata.query;
        this.polizeStatus = tempdata.polizeStatus;
        this.renewalStatusNomId = tempdata.renewalStatusNomId;
        this.cotizedOnly = tempdata.cotizedOnly;
        this.preferLanguage = tempdata.preferLanguage;
        this.companyId = tempdata.companyId;
        this.companyStateId = tempdata.companyStateId;
        this.sourceId = tempdata.sourceId;
        this.tagId = tempdata.tagId;
        this.npnNomIds = tempdata.npnNomIds;
        this.payToNomIds = tempdata.payToNomIds;
        this.paymentStatusNomIds = tempdata.paymentStatusNomIds;
        this.noPaymentStatusNomIds = tempdata.noPaymentStatusNomIds;
        this.noCoordinator = tempdata.noCoordinator;
        this.effectiveDate = tempdata.effectiveDate;
        this.employeeUuid = tempdata.employeeUuid;
        this.polizeNumber = tempdata.polizeNumber;
        this.sortAscending = tempdata.sortAscending;

        this.agentUuid = tempdata.agentUuid;
        this.createdInterval = tempdata.createdInterval;
      }

      switch (this.filterDate) {
        case "today":
          this.createdInterval = this.intervalToday;
          break;
        case "yesterday":
          this.createdInterval = this.intervarYesterDay;
          break;
        case "this-week":
          this.createdInterval = this.intervarThisWeek;
          break;
        case "last-week":
          this.createdInterval = this.intervarLastWeek;
          break;
        case "this-month":
          this.createdInterval = this.intervarThisMonth;
          break;
        case "last-month":
          this.createdInterval = this.intervarLastMonth;
          break;
        case "this-year":
          this.createdInterval = this.intervarThisYear;
          break;
        case "last-year":
          this.createdInterval = this.intervarLastYear;
          break;
        case "custom":
          if (this.dateFrom == null || this.dateTo == null) {
            this.createdInterval = {
              date1: "",
              date2: "",
            };
          } else {
            this.createdInterval = {
              date1: moment(this.dateFrom).startOf("day").toISOString(),
              date2: moment(this.dateTo).endOf("day").toISOString(),
            };
          }
          break;
        case "clear":
          this.createdInterval = {
            date1: "",
            date2: "",
          };
          this.dateFrom = null;
          this.dateTo = null;
          break;
      }

      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.reqrange = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(this.reqrange);

      localStorage.setItem(
        "filtersPatients",
        JSON.stringify({
          query: this.query,
          polizeStatus: this.polizeStatus,
          renewalStatusNomId: this.renewalStatusNomId,
          employeeUuid: this.employeeUuid,
          polizeNumber: this.polizeNumber,
          cotizedOnly: this.cotizedOnly,
          sortAscending: this.sortAscending,
          preferLanguage: this.preferLanguage,
          companyId: this.companyId,
          companyStateId: this.companyStateId,
          sourceId: this.sourceId,
          tagId: this.tagId,
          npnNomIds: this.npnNomIds,
          payToNomIds: this.payToNomIds,
          paymentStatusNomIds: this.paymentStatusNomIds,
          noPaymentStatusNomIds: this.noPaymentStatusNomIds,
          agentUuid: this.agentUuid,
          createdInterval: this.createdInterval,
          noCoordinator: this.noCoordinator,
          effectiveDate: this.effectiveDate,
          page,
          itemsPerPage,
        })
      );
      localStorage.removeItem("filterfrompatients");

      let body = {
        query: this.query,
        polizeStatus: this.polizeStatus,
        renewalStatusNomId: this.renewalStatusNomId,
        range: this.reqrange,
        polizeNumber: this.polizeNumber,
        cotizedOnly: this.cotizedOnly,
        preferLanguage: this.preferLanguage,
        sourceId: this.sourceId,
        tagId: this.tagId,
        npnNomIds: this.npnNomIds,
        payToNomIds: this.payToNomIds,
        paymentStatusNomIds: this.paymentStatusNomIds,
        noPaymentStatusNomIds: this.noPaymentStatusNomIds,
        sortAscending: this.sortAscending,
        companyId: this.companyId,
        companyStateId: this.companyStateId,
        employeeUuid: this.employeeUuid,
        noCoordinator: this.noCoordinator,
        effectiveDate: this.effectiveDate,
        agentUuid: this.agentUuid,
        createdInterval: this.createdInterval,
      };
      this.query1 = this.createdInterval;

      if (this.filterDate == "clear") {
        delete body.createdInterval;
        this.query1 = { date1: "", date2: "" };
      }
      if (
        this.filterDate == "custom" &&
        (this.createdInterval.date1 == "" || this.createdInterval.date2 == "")
      ) {
        delete body.createdInterval;
        this.query1 = { date1: "", date2: "" };
      }

      this.toExport = body;
      this.loading = true;

      if (body.query == "") {
        delete body.query;
      }

      if (body.effectiveDate && body.effectiveDate.length == 0) {
        delete body.effectiveDate;
      }
      if (body.companyId) {
        body.companyId = Number(body.companyId);
      }
      if (body.companyStateId) {
        body.companyStateId = Number(body.companyStateId);
      }
      if (body.polizeNumber == "") {
        delete body.polizeNumber;
      }
      if (body.tagId && body.tagId.length == 0) {
        delete body.tagId;
      }
      if (body.npnNomIds && body.npnNomIds.length == 0) {
        delete body.npnNomIds;
      }
      if (body.payToNomIds && body.payToNomIds.length == 0) {
        delete body.payToNomIds;
      }

      if (
        (body.noPaymentStatusNomIds && body.noPaymentStatusNomIds.length == 0) ||
        body.paymentStatusNomIds == null ||
        body.paymentStatusNomIds.length == 0
      ) {
        delete body.noPaymentStatusNomIds;
      }
      if (body.noPaymentStatusNomIds && body.noPaymentStatusNomIds.length != 0) {
        delete body.paymentStatusNomIds;
      }
      if (body.paymentStatusNomIds && body.paymentStatusNomIds.length == 0) {
        delete body.paymentStatusNomIds;
      }
      body = cleanObject(body);
      getAPI
        .post("/patient/filterPatients", body)
        .then((res) => {
          this.loading = false;
          this.patients = res.data.result.map((dr) => {
            return { ...dr, patientTagsNom: this.getOrderTags(dr.patientTagsNom) };
          });
          this.totalpatient = res.data.count;
          this.getSummary(body);
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getOrderTags(tags) {
      const temp = tags.sort((a, b) => a.tagNom.id - b.tagNom.id);
      return temp;
    },

    setPatientToSendDocument(patient) {
      this.currentPatient = patient;
      this.dialogDocument = true;
    },
    sendStep(step, patientUuid) {
      this.loadingSendStep = true;
      getAPI.post(`/patient/sendstep${step}/${patientUuid}`).then(() => {
        notifyInfo("The documents have been Sent");
        this.loadingSendStep = false;
      });
    },
    showSignDialog(patient) {
      this.signature = new Blob();
      this.currentPatient = patient;
      this.dialogSignStep1 = true;
    },
    signAdminStep1() {
      const formData = new FormData();

      this.loadingSignStep1 = true;
      formData.append("firma", this.signature, `signature-${this.currentPatient.uuid}`);
      getAPI({
        method: "post",
        url: "/patient/uploadAdminSign/" + this.currentPatient.uuid,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);

          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        });
    },
    showDetails(patient) {
      this.$router.push(`/clients/details/${patient.uuid}`);
    },

    transfer() {
      this.loadingAction = true;
      const body = Object.assign({}, this.relation);
      const patientTransfer = this.selected.map((patient) => patient.uuid);
      body.patientUuids = patientTransfer;
      body.employeeUuid = this.employeeUuidt;
      getAPI
        .post("/patient/createMultipleRelations", body)
        .then(() => {
          notifySuccess(this.$t("Patients Relationship has been created"));
          this.getPatients();
          this.closedTransfer();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    deleteRelation() {
      this.loadingAction = true;
      const body = Object.assign({}, this.relation);
      const patientTransfer = this.selected.map((patient) => patient.uuid);
      body.employeeUuid = this.employeeUuidDel.map((eud) => {
        return eud.uuid;
      });

      body.patientUuids = patientTransfer;
      getAPI
        .post("/patient/deleteMultipleRelations", {
          employeeUuids: body.employeeUuid,
          patientUuids: body.patientUuids,
        })
        .then(() => {
          notifyInfo(this.$t("Patients Relationship has been deleted"));
          this.getPatients();
          this.closedTransfer();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    closedTransfer() {
      this.transferDialog = false;
      this.deleteDialog = false;
      this.employeeUuidt = "";
      this.loadingOcup = false;
      this.loadingAction = false;
      this.selectedAllEmpleDel = false;
      this.relation = {
        employeeUuid: "",
        ocupationId: null,
        patientUuids: [],
      };
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },

    clearDateInterval() {
      this.dateFrom = null;
      this.dateTo = null;
      this.getPatients();
    },
    colorShowS(item) {
      return item.sourceNom ? item.sourceNom.color : "#000";
    },
    colorSourceEye(data) {
      if (this.references.length != 0) {
        const x = this.references.find((r) => r.name == data.source);
        return x ? x.color : "#000";
      }
      return "#000";
    },
    colorShowT(item) {
      return item.tagNom ? item.tagNom.color : "#000";
    },
    colorShow(item) {
      const status = item.polizeStatus ? item.polizeStatus : item.status;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "CHARGE_BACK":
            return "#9366C6";
          case "POLICY_BLACKLIST":
            return "#270E41";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    statusShow(item) {
      const status = item.polizeStatus ? item.polizeStatus : item.status;
      if (status) {
        const st = PolicyStatus[status];
        return st;
      }

      return "-";
    },

    getAgent(value) {
      if (value.createdBy) {
        return value.createdBy.fullname ? value.createdBy.fullname : "-";
      }
      return "-";
    },

    gotosetStatus(item, stat) {
      this.setStatusObject = {
        dialog: true,
        value: stat,
        item,
        loading: false,
        note: "",
      };
    },
    cancelSetStatus() {
      this.setStatusObject = {
        dialog: false,
        value: null,
        item: null,
        loading: false,
        note: "",
      };
    },

    setStatus() {
      this.setStatusObject.loading = true;
      getAPI
        .put(`/patient/updatePatient/${this.setStatusObject.item.uuid}`, {
          polizeStatus: this.setStatusObject.value.value,
          noteForStatusChange: this.setStatusObject.note,
        })
        .then(() => {
          notifyInfo(this.$t("Status has been updated"));
          this.setStatusObject.loading = false;
          this.cancelSetStatus();
          this.getPatients();
        })
        .catch((error) => {
          this.setStatusObject.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    colorPolicy(value) {
      const status = value;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    exportClients() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      const date = moment().format("MM/DD/YYYY HH:mm");

      const name = this.getpatientN + "s-" + date + ".xlsx";
      this.donwloading = true;
      let body = Object.assign({}, this.toExport);
      if (this.checkToExport.length != 0) {
        body = { ...body, exportOnly: this.checkToExport };
      }

      const url = apiBaseUrl + "/patient/filterPatients?export=true";
      axios({
        url: url,
        method: "POST",
        responseType: "blob",
        headers: auth,
        data: body,
      })
        .then((res) => {
          this.donwloading = false;
          const file_path = URL.createObjectURL(res.data);
          const a = document.createElement("A");
          a.href = file_path;
          a.setAttribute("download", name);
          a.setAttribute("target", "_blank");
          a.click();
          this.cancelExport();
        })
        .catch((err) => {
          this.donwloading = false;

          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getRenewalStatus() {
      this.loadingRenewalS = true;
      getAPI("/renewall-status/list")
        .then((res) => {
          this.loadingRenewalS = false;
          this.renewalStatus = res.data;
        })
        .catch((error) => {
          this.loadingRenewalS = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    listNpns() {
      this.npnloading = true;

      getAPI(`/npn-nom/list`)
        .then((res) => {
          this.npns = res.data.filter((r) => r.status == "ACTIVE");

          this.npnloading = false;
        })
        .catch((error) => {
          this.npnloading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    listPayto() {
      this.paytoloading = true;

      getAPI(`/pay-to-nom/list`)
        .then((res) => {
          this.paysTo = res.data.filter((r) => r.status == "ACTIVE");

          this.paytoloading = false;
        })
        .catch((error) => {
          this.paytoloading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    listPaymentStatus() {
      this.paytoloading = true;

      getAPI(`/payment-status/list`)
        .then((res) => {
          this.paymentStatus = res.data.filter((r) => r.status == "ACTIVE");

          this.paytoloading = false;
        })
        .catch((error) => {
          this.paytoloading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getColorRStatus(item) {
      if (this.renewalStatus.length != 0) {
        const rst = this.renewalStatus.find((rs) => rs.name == item.renewalstatus);
        return rst ? rst.color : "#000";
      } else {
        return "#000";
      }
    },
    getColorPayTo(item) {
      if (this.paysTo.length != 0) {
        const rst = this.paysTo.find((rs) => rs.name == item.payto);
        return rst ? rst.color : "#000";
      } else {
        return "#000";
      }
    },
    getColorPaymentStatus(item) {
      if (this.paymentStatus.length != 0) {
        const rst = this.paymentStatus.find((rs) => rs.name == item.paymentstatus);
        return rst ? rst.color : "#000";
      } else {
        return "#000";
      }
    },
    getColorTag(item) {
      if (this.tags.length != 0) {
        const rst = this.tags.find((rs) => rs.name == item.tagnom);
        return rst ? rst.color : "#000";
      } else {
        return "#000";
      }
    },
    getColorNpn(item) {
      if (this.npns.length != 0) {
        const rst = this.npns.find((rs) => rs.name == item.npn);
        return rst ? rst.color : "#000";
      } else {
        return "#000";
      }
    },

    async clearFilters() {
      this.cleaning = true;
      this.sourceId = null;
      this.employeeUuid = null;
      this.companyId = null;
      this.companyStateId = null;
      this.tagId = null;
      this.npnNomIds = null;
      this.payToNomIds = null;
      this.paymentStatusNomIds = null;
      this.noPaymentStatusNomIds = null;
      this.polizeStatus = null;
      this.renewalStatusNomId = null;
      this.effectiveDate = [];
      this.checkAllNpns = false;
      this.checkAllPaysTo = false;
      this.checkAllTags = false;
      this.$refs.mtp.cancelMenu();
      await this.getPatients();
      this.cleaning = false;
    },
  },
});
</script>

<style lang="scss">
.patient-list {
  .v-data-table {
    tbody {
      tr {
        cursor: pointer !important;
      }
    }
  }
}
.chipstag {
  display: flex;
  overflow: auto;
}
.chipsCols {
  width: 150px !important;
}

.contentCols {
  width: 160px !important;
}

.swipri {
  margin-top: -1px !important;
}

.namepatient {
  position: relative;
}

.notificationdocument {
  position: absolute;
  top: -5px;
  right: 3px;
}

.toolt {
  width: 320px !important;
}

.status-active {
  color: rgb(231, 49, 49) !important;
}
</style>

<i18n>
{
  "en": {
    "Fullname": "Full name",
    "dialogTitle": "Reason why you open the details of :",
    "notification": "Successfully removed",
    "labelGender": "Gender",
    "labelHomePhone": "Phone",
    "labelEmergencyPhone": "Emergency phone"

  },
  "es": {
    "Fullname": "Nombre completo",
    "dialogTitle": "Razon por la que abre los detalles de :",
    "notification": "Eliminado con éxito",
    "labelGender": "Género",
    "labelHomePhone": "Teléfono",
    "labelEmergencyPhone": "Teléfono de emergencias"
  }
}
</i18n>
